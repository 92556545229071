export const studentprojects = [
    {
        title: "Binary Neural Networks for Eye Tracking on Neuromorphic Event Data",
        description:
            "Develop a lightweight and high-speed architecture optimized for embedded hardware using BNNs for eye tracking on event-based data.",
        keywords: ["Computer Vision", "Binary Neural Network"],
        contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
        moreInfo: "https://sirop.org/app/b6155b63-5184-41a5-b32c-d5464cb50983",
    },
    {
        title: "TinySAM2: Segmenting Videos and Images with Sony IMX500",
        description:
            "Adapt video and image segmentation algorithms for resource-constrained AI-in-sensor devices like the Sony IMX500.",
        keywords: ["Computer Vision", "Segmentation"],
        contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
        moreInfo: "https://sirop.org/app/22dc4ccb-de73-412f-97f6-39c2a1e60bb3",
    },
    {
        title: "Convolutional Differentiable Logic Gate Networks for Object Localization",
        description:
            "Extend logic gate networks (LGNs) for object localization in computer vision tasks.",
        keywords: ["Computer Vision", "Logic Networks"],
        contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
        moreInfo: "https://sirop.org/app/fdc257f9-12f2-4f6c-875b-dc337525c9ad",
    },
];