export const news = [
  {
    name: "chinatrip",
    title: "Invited to a 10-days trip in China to showcase our work on Smart Glasses.",
    description: "I've travelled to several development areas in Jiangsu, Zhejiang and Tianjin and and discovered the innovation culture of the Yiangtze River Region.",
    website: "https://mp.weixin.qq.com/s?__biz=MzA4MzQ0OTE5MA==&mid=2653048992&idx=1&sn=cf9badd6b7dbf6c2193d447c1362662b&chksm=85340e7cd3f44fa0c1273ce55ca7bf328a8cc19bb140313c0e92317e33cd8f42d8b243a997ce&scene=27"
  },
  {
    name: "eccv",
    title: "On-Device Object Detection was accepted at the ECCV Workshop in Milan (IT)",
    description: "I'm excited to share that, our work on object detection on smart glasses has been accepted at the ICVSE workshop, at ECCV 2024.",
    website: "https://eccv.ecva.net/virtual/2024/workshop/76"
  },
  {
    name: "cvpr",
    title: "Presenting Retina at CVPR Workshop in Seattle (US): A Novel Event-Based Eye Tracking System and Dataset",
    description: "I'm excited to present Retina, a novel event-based eye tracking system and dataset, at the 1st Workshop on AI for Streaming at CVPR 2024.",
    website: "https://ai4streaming-workshop.github.io/"
  },
  {
    name: "aicas",
    title: "Q-Segment Accepted at AICAS Conference",
    description: "The paper was accepted for presentation at the prestigious 6th IEEE International Conference for AICAS held at Khalifa University in Abu Dhabi.",
    website: "https://arxiv.org/html/2312.09854v2"
  },
  {
    name: "ef",
    title: "Joining EF 2024 Cohort to Refine Entrepreneurial Skills",
    description: "Thrilled to be joining the EF 2024 Cohort! I'll be learning valuable skills to validate entrepreneurial ideas and test co-founder partnerships.",
    website: "https://www.joinef.com"
  },

  {
    name: "anthropic",
    title: "Great Reviews for Anthropic's on our Smart Glass Preprint",
    description: "Anthropic's CEO discussed TinyissimoYOLO on ImportAI (Jack Clark's newsletter). We'll keep working on the final system to open source hardware and software!",
    website: "https://jack-clark.net/"
  },
  {
    name: "tinytracker",
    title: "Positive Analysis of TinyTracker in ML News",
    description: "Great to see Nick Bild's insightful analysis of TinyTracker featured on Hackster.io!",
    website: "https://www-hackster-io.cdn.ampproject.org/c/s/www.hackster.io/news/gazing-into-the-future-of-ai-57d4acd77f4b.amp"
  },
  {
    name: "picovolts",
    title: "Hardware Innovation Grant, 2023",
    description: "Thrilled to announce receiving the Hardware Innovation Grant in 2023!",
    website: "https://picovolts.com"
  },

  {
    name: "novatalent",
    title: "Honored by Nova Talent Italian List 2022",
    description: "Humbled to be invited to the Gala organized by Bocconi University and Nova Talent for being included in the Nova Talent Italian List 2022 for Computer Science.",
    website: "https://www.novatalent.com/111/italy/student-list/2022"
  },

  {
    name: "rpg",
    title: "Few-Shot Point Cloud Reconstruction and Denoising",
    description: "Excited to share my work on few-shot point cloud reconstruction and denoising using learned Gaussian splats and fine-tuned diffusion features, part of the Robotics & Perception Group at Google Inc.",
    website: "https://pietrobonazzi.com/projects/splatting"
  },
  {
    name: "google",
    title: "3D Scene Generation from Scene Graphs",
    description: "Proud to present our project on 3D scene generation from scene graphs and self-attention, developed with the Robotics & Perception Group at Google Inc.",
    website: "https://pietrobonazzi.com/projects/scenegraph"
  }
]