import React from "react";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import { ThemeProvider } from "styled-components";

import SecondaryNews from "../../components/News/SecondaryNews/SecondaryNews";
import PrimaryNews from "../../components/News/PrimaryNews/PrimaryNews";
import { news } from "../../data/news_list";

import StudentProject from "../StudentProject";
import { studentprojects } from "../../data/studentproject_list";

import styled from "styled-components";
import AboutMe from "./AboutMe";

const StyledSection = styled.div`
    margin: 20px 0;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const StyledProjectsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
`;


function FirstPage() {
    const newsItems = news.map((item) => (
        <SecondaryNews
            key={item.name}
            name={item.name}
            title={item.title}
            description={item.description}
            website={item.website}
        />
    ));



    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div style={{ display: "flex", flexDirection: "column", padding: 30, justifyContent: "center", backgroundColor: "white" }}>

                <AboutMe name={"Pietro Bonazzi"} description={"I am a PhD Researcher at ETH Zürich, Switzerland, working with PD Dr. Michele Magno. Previously, I received my MSc. degree from the University of Zürich, supervised by Prof. Scaramuzza. I was a research student at Google for my Master Thesis and Project."} />

                <StyledSection>
                    <h2 style={{ fontSize: "23px", marginBottom: "30px", textAlign: "center", color: "black" }}>Open Projects</h2>
                    <StyledProjectsSection>
                        {studentprojects.map((project, index) => (
                            <StudentProject key={index} {...project} />
                        ))}
                    </StyledProjectsSection>
                </StyledSection>

                <StyledSection>
                    <h2 style={{ fontSize: "23px", marginBottom: "30px", textAlign: "center", color: "black" }}>News & Publications</h2>

                    {newsItems}


                </StyledSection>
            </div>



        </ThemeProvider >
    );
}
export default FirstPage; 